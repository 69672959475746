<template>
  <el-tour v-model="open" @change="handleStepChange">
    <el-tour-step :target="refs.ref1?.$el" placement="right">
      <div class="title">Manage your reports</div>
      <div>
        On this panel, you will be able to organize all your deals. You can also
        filter them by date.
      </div>
    </el-tour-step>

    <el-tour-step :target="refs.ref2?.$el" placement="right">
      <div class="title">Deal Management</div>
      <div>
        Access a list of all your deals right here. You can also edit or delete
        them if needed.
      </div>
    </el-tour-step>

    <el-tour-step :target="refs.ref3?.$el" placement="right">
      <div class="title">Almost there! - View Tickets</div>
      <div>
        View all tickets assigned to you here, you can create, track, and manage
        support tickets for your clients to improve customer satisfaction.
      </div>
    </el-tour-step>
  </el-tour>
</template>

<script>
import { ref, onMounted, nextTick } from "vue";
import { useRouter } from "vue-router";

export default {
  props: {
    refs: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const open = ref(false);
    const ref1 = ref(null);
    const ref2 = ref(null);
    const ref3 = ref(null);
    const router = useRouter();
    const tourStepsRoutes = [
      "/icp-dashboard",
      "/market/deals",
      "/help-desk/tickets",
    ];

    const handleStepChange = (currentStep) => {
      if (tourStepsRoutes[currentStep]) {
        router.push(tourStepsRoutes[currentStep]);
      }
    };
    nextTick(() => {
      const isOpenInLocalStorage = localStorage.getItem("open") === "true";
      if (isOpenInLocalStorage) {
        setTimeout(() => {
          open.value = true;
        }, 2000);
      }
    });

    return {
      open,
      ref1,
      ref2,
      ref3,
      handleStepChange,
    };
  },
  methods: {
    openTicketingMenu() {
      console.log("openTicketingMenu triggered");
      if (this.ref3) {
        this.submenuOpen = true;
        this.isActive = "Performance Insights"; // Main menu
        this.isSubmenuActive = "Tickets"; // Submenu
      }
    },
  },
};
</script>
